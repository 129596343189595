/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700");
@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  width: 100vw;
  height: 100vh;
}

::ng-deep ngb-modal-backdrop {
  z-index: 1050 !important;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.multiselect-dropdown .dropdown-btn {
  border: unset !important;
}

.label-up + label {
  top: -11px !important;
  margin-top: 0 !important;
  font-size: 12px !important;
  color: #3a3a3a !important;
  font-weight: 500 !important;
}

.c-pointer {
  cursor: pointer;
}

.content {
  padding: 0 0.5rem;
}

:host {
  display: flex;
  justify-content: center;
  align-items: center;
}

pf-dropdown {
  border: none;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 8rem;
}
pf-dropdown .dropdown-item {
  padding: 0.5rem 1rem;
}
pf-dropdown .text-sm {
  margin-bottom: 0;
}
pf-dropdown .dropdown-divider {
  margin: 0;
}

.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}

/* Importing Bootstrap SCSS file. */
/* Importing PSTitle SCSS file. */
/* 
* PS Title : Title with customization
* Author: Sanam Mijar
*
* - To use this custom title followings are important
*
*   1) Attribute called 'pstitle' should be used to display data
*   2) CSS classes
*      --> Title exactly above the hovered view
*        .pstitle-normal = To display normal title
*        .pstitle-success = To display succss title
*        .pstitle-danger = To display error title
*        .pstitle-warning = To display warning title
*        .pstitle-primary = To display primary title
*        .pstitle-secondary = To display secondary title
*
*      --> Title starting from (left to right) side of the hovered view
*        .pstitle-normal-left = To display normal title
*        .pstitle-success-left = To display succss title
*        .pstitle-danger-left = To display error title
*        .pstitle-warning-left = To display warning title
*        .pstitle-primary-left = To display primary title
*        .pstitle-secondary-left = To display secondary title
*
*      --> Title starting from (right to left) side of the hovered view
*        .pstitle-normal-right = To display normal title
*        .pstitle-success-right = To display succss title
*        .pstitle-danger-right = To display error title
*        .pstitle-warning-right = To display warning title
*        .pstitle-primary-right = To display primary title
*        .pstitle-secondary-right = To display secondary title
*/
.pstitle-normal,
.pstitle-success,
.pstitle-danger,
.pstitle-warning,
.pstitle-primary,
.pstitle-secondary,
.pstitle-info,
.pstitle-normal-left,
.pstitle-success-left,
.pstitle-danger-left,
.pstitle-warning-left,
.pstitle-primary-left,
.pstitle-secondary-left,
.pstitle-info-left,
.pstitle-normal-right,
.pstitle-success-right,
.pstitle-danger-right,
.pstitle-warning-right,
.pstitle-primary-right,
.pstitle-secondary-right,
.pstitle-info-right {
  position: relative;
}

.pstitle-normal:hover,
.pstitle-success:hover,
.pstitle-danger:hover,
.pstitle-warning:hover,
.pstitle-primary:hover,
.pstitle-secondary:hover,
.pstitle-info:hover,
.pstitle-normal-left:hover,
.pstitle-success-left:hover,
.pstitle-danger-left:hover,
.pstitle-warning-left:hover,
.pstitle-primary-left:hover,
.pstitle-secondary-left:hover,
.pstitle-info-left:hover,
.pstitle-normal-right:hover,
.pstitle-success-right:hover,
.pstitle-danger-right:hover,
.pstitle-warning-right:hover,
.pstitle-primary-right:hover,
.pstitle-secondary-right:hover,
.pstitle-info-right:hover {
  z-index: 99999;
}

.pstitle-normal,
.pstitle-success,
.pstitle-danger,
.pstitle-warning,
.pstitle-primary,
.pstitle-secondary,
.pstitle-info :hover {
  text-decoration: none !important;
}

.pstitle-normal:hover::before,
.pstitle-success:hover::before,
.pstitle-danger:hover::before,
.pstitle-warning:hover::before,
.pstitle-primary:hover::before,
.pstitle-secondary:hover::before,
.pstitle-info:hover::before {
  content: attr(pstitle);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0.25rem;
  pointer-events: none;
}

.pstitle-normal-left:hover::before,
.pstitle-success-left:hover::before,
.pstitle-danger-left:hover::before,
.pstitle-warning-left:hover::before,
.pstitle-primary-left:hover::before,
.pstitle-secondary-left:hover::before,
.pstitle-info-left:hover::before {
  content: attr(pstitle);
  position: absolute;
  bottom: 100%;
  left: -4px !important;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0.25rem;
  pointer-events: none;
}

.pstitle-normal-right:hover::before,
.pstitle-success-right:hover::before,
.pstitle-danger-right:hover::before,
.pstitle-warning-right:hover::before,
.pstitle-primary-right:hover::before,
.pstitle-secondary-right:hover::before,
.pstitle-info-right:hover::before {
  content: attr(pstitle);
  position: absolute;
  bottom: 100%;
  right: -4px !important;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0.25rem;
  pointer-events: none;
}

.pstitle-normal:hover::before,
.pstitle-normal-left:hover::before,
.pstitle-normal-right:hover::before {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

.pstitle-success:hover::before,
.pstitle-success-left:hover::before,
.pstitle-success-right:hover::before {
  color: #ffffff;
  background-color: #28a745;
}

.pstitle-danger:hover::before,
.pstitle-danger-left:hover::before,
.pstitle-danger-right:hover::before {
  color: #fff;
  background-color: #dc3545;
}

.pstitle-warning:hover::before,
.pstitle-warning-left:hover::before,
.pstitle-warning-right:hover::before {
  color: #ffffff;
  background-color: #ffc107;
}

.pstitle-primary:hover::before,
.pstitle-primary-left:hover::before,
.pstitle-primary-right:hover::before {
  color: #ffffff;
  background-color: #007bff;
}

.pstitle-secondary:hover::before,
.pstitle-secondary-left:hover::before,
.pstitle-secondary-right:hover::before {
  color: #ffffff;
  background-color: #6c757d;
}

.pstitle-info:hover::before,
.pstitle-info-left:hover::before,
.pstitle-info-right:hover::before {
  color: #ffffff;
  background-color: #17a2b8;
}

/* PSTitle for table td hover */
.pstitle-normal-td:hover,
.pstitle-normal-left-td:hover,
.pstitle-normal-right-td:hover {
  cursor: pointer;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

.pstitle-success-td:hover,
.pstitle-success-left-td:hover,
.pstitle-success-right-td:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #28a745;
}

.pstitle-danger-td:hover,
.pstitle-danger-left-td:hover,
.pstitle-danger-right-td:hover {
  cursor: pointer;
  color: #fff;
  background-color: #dc3545;
}

.pstitle-warning-td:hover,
.pstitle-warning-left-td:hover,
.pstitle-warning-right-td:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #ffc107;
}

.pstitle-primary-td:hover,
.pstitle-primary-left-td:hover,
.pstitle-primary-right-td:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #007bff;
}

.pstitle-secondary-td:hover,
.pstitle-secondary-left-td:hover,
.pstitle-secondary-right-td:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #6c757d;
}

.pstitle-info-td:hover,
.pstitle-info-left-td:hover,
.pstitle-info-right-td:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #17a2b8;
}

/* Importing PSMatInput SCSS file. */
/**
* This ps-mat-input field created by Sanam Mijar on 19 Jun 2023
* Usage: For Text Input
*    <div class="ps-mat-input">
*        <input id="text" type="text" placeholder="Text" name="text" />
*        <label for="text">Text</label>
*    </div>
*
* Usage: For Input Append
*    
*  <div class="ps-mat-input">
*      <div class="ps-mat-inp-grp">
*          <input type="text" class="ps-mat-inp-append" required>
*          <label for="text">Text</label>
*      </div>
*      <button class="btn-sm btn btn-outline-secondary ps-mat-inp-grp-append" type="button">Button</button>
*  </div>
*
* Usage: For Input Prepend
*    
*  <div class="ps-mat-input">
*      <div class="ps-mat-inp-grp">
*          <input type="text" class="ps-mat-inp-prepend" required>
*          <label for="text">Text</label>
*      </div>
*      <button class="btn-sm btn btn-outline-secondary ps-mat-inp-grp-prepend" type="button">Button</button>
*  </div>
*
* Usage: For Select
*    <div class="ps-mat-input">
*      <select name="select">
*        <option value="1">1</option>
*        <option value="2">2</option>
*      </select>
*      <label for="select">Select</label>
*    </div>
*/
.ps-mat-input {
  position: relative !important;
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
  padding: 4px !important;
}

.ps-mat-input input {
  font-size: 16px !important;
  padding: 8px !important;
  display: block !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.ps-mat-input textarea {
  width: 100%;
  height: auto;
  padding: 0.5rem;
  border: unset;
  border-radius: 4px;
  resize: unset;
}

.ps-mat-input select {
  font-size: 16px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  display: block !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.ps-mat-input input:-webkit-autofill:focus {
  background-color: transparent !important;
}

.ps-mat-input input:focus, .ps-mat-input textarea:focus, .ps-mat-input select:focus {
  outline: none !important;
}

.ps-mat-input label {
  font-size: 14px !important;
  font-weight: 500 !important;
  position: absolute !important;
  color: #6d6d6d;
  background-color: white !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  top: 8px !important;
  margin-top: 6px !important;
  left: 8px !important;
  pointer-events: none !important;
  transition: 0.2s ease all !important;
}

.ps-mat-input .ps-mat-inp-grp {
  position: relative !important;
}

.ps-mat-input input.ps-mat-inp-append {
  padding-right: 40px !important;
}

.ps-mat-input input.ps-mat-inp-prepend {
  padding-left: 40px !important;
}

.ps-mat-input .ps-mat-inp-grp-prepend,
.ps-mat-input .ps-mat-inp-grp-append {
  position: absolute !important;
  top: 0 !important;
}

.ps-mat-input .ps-mat-inp-grp-prepend,
.ps-mat-input .ps-mat-inp-grp-append {
  border: unset !important;
}

.ps-mat-input .ps-mat-inp-grp-prepend {
  left: 0 !important;
}

.ps-mat-input .ps-mat-inp-grp-append {
  right: 0 !important;
}

.ps-mat-input label.active {
  top: -20px !important;
  font-size: 14px !important;
  color: #9b9797 !important;
}

.ps-mat-input input:focus:not(:placeholder-shown) + label,
.ps-mat-input input:not([required]):focus:not(:placeholder-shown) + label,
.ps-mat-input input:valid:not(:placeholder-shown) + label,
.ps-mat-input input:read-only:not(:placeholder-shown) + label,
.ps-mat-input input.ps-mat-inp-append:focus:not(:placeholder-shown) + label,
.ps-mat-input input.ps-mat-inp-prepend:focus:not(:placeholder-shown) + label,
.ps-mat-input textarea:focus:not(:placeholder-shown) + label,
.ps-mat-input textarea:not([required]):focus:not(:placeholder-shown) + label,
.ps-mat-input textarea:valid:not(:placeholder-shown) + label,
.ps-mat-input textarea:read-only:not(:placeholder-shown) + label,
.ps-mat-input textarea.ps-mat-inp-append:focus:not(:placeholder-shown) + label,
.ps-mat-input textarea.ps-mat-inp-prepend:focus:not(:placeholder-shown) + label,
.ps-mat-input select:focus:not(:placeholder-shown) + label,
.ps-mat-input select:valid:not(:placeholder-shown) + label {
  top: -11px !important;
  margin-top: 0 !important;
  font-size: 12px !important;
  color: #3a3a3a !important;
  font-weight: 500 !important;
}

.ps-mat-input .ps-mat-inp-grp input:focus + label,
.ps-mat-input .ps-mat-inp-grp input:valid + label,
.ps-mat-input .ps-mat-inp-grp input:read-only + label,
.ps-mat-input .ps-mat-inp-grp input.ps-mat-inp-append:focus + label,
.ps-mat-input .ps-mat-inp-grp input.ps-mat-inp-prepend:focus + label,
.ps-mat-input .ps-mat-inp-grp textarea:focus + label,
.ps-mat-input .ps-mat-inp-grp textarea:valid + label,
.ps-mat-input .ps-mat-inp-grp textarea:read-only + label,
.ps-mat-input .ps-mat-inp-grp textarea.ps-mat-inp-append:focus + label,
.ps-mat-input .ps-mat-inp-grp textarea.ps-mat-inp-prepend:focus + label,
.ps-mat-input .ps-mat-inp-grp select:focus + label,
.ps-mat-input .ps-mat-inp-grp select:valid + label {
  top: -11px !important;
  margin-top: 0 !important;
  font-size: 12px !important;
  color: #3a3a3a !important;
  font-weight: 500 !important;
}

.ps-mat-input input:not([required]):empty + label {
  top: 6px !important;
  margin-top: 6px !important;
}

.ps-mat-input-disabled input, .ps-mat-input-disabled textarea, .ps-mat-input-disabled select {
  pointer-events: none;
  background-color: #ffffff;
}

/* Importing Margin SCSS file. */
.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mlr-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mlr-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mlr-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mlr-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mtb-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mtb-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mtb-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mtb-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.m--1 {
  margin: -0.25rem !important;
}

.m--2 {
  margin: -0.5rem !important;
}

.m--3 {
  -margin: 1rem !important;
}

.m--4 {
  margin: -1.5rem !important;
}

.ml--1 {
  margin-left: -0.25rem !important;
}

.ml--2 {
  margin-left: -0.5rem !important;
}

.ml--3 {
  margin-left: -1rem !important;
}

.ml--4 {
  margin-left: -1.5rem !important;
}

.mr--1 {
  margin-right: -0.25rem !important;
}

.mr--2 {
  margin-right: -0.5rem !important;
}

.mr--3 {
  margin-right: -1rem !important;
}

.mr--4 {
  margin-right: -1.5rem !important;
}

.mt--1 {
  margin-top: -0.25rem !important;
}

.mt--2 {
  margin-top: -0.5rem !important;
}

.mt--3 {
  margin-top: -1rem !important;
}

.mt--4 {
  margin-top: -1.5rem !important;
}

.mb--1 {
  margin-bottom: -0.25rem !important;
}

.mb--2 {
  margin-bottom: -0.5rem !important;
}

.mb--3 {
  margin-bottom: -1rem !important;
}

.mb--4 {
  margin-bottom: -1.5rem !important;
}

.mlr--1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.mlr--2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.mlr--3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mlr--4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mtb--1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.mtb--2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.mtb--3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.mtb--4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

/* Importing Padding SCSS file. */
.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.plr-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.plr-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.plr-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.plr-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.ptb-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.ptb-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.ptb-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.ptb-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.p--1 {
  padding: -0.25rem !important;
}

.p--2 {
  padding: -0.5rem !important;
}

.p--3 {
  -padding: 1rem !important;
}

.p--4 {
  padding: -1.5rem !important;
}

.pl--1 {
  padding-left: -0.25rem !important;
}

.pl--2 {
  padding-left: -0.5rem !important;
}

.pl--3 {
  padding-left: -1rem !important;
}

.pl--4 {
  padding-left: -1.5rem !important;
}

.pr--1 {
  padding-right: -0.25rem !important;
}

.pr--2 {
  padding-right: -0.5rem !important;
}

.pr--3 {
  padding-right: -1rem !important;
}

.pr--4 {
  padding-right: -1.5rem !important;
}

.pt--1 {
  padding-top: -0.25rem !important;
}

.pt--2 {
  padding-top: -0.5rem !important;
}

.pt--3 {
  padding-top: -1rem !important;
}

.pt--4 {
  padding-top: -1.5rem !important;
}

.pb--1 {
  padding-bottom: -0.25rem !important;
}

.pb--2 {
  padding-bottom: -0.5rem !important;
}

.pb--3 {
  padding-bottom: -1rem !important;
}

.pb--4 {
  padding-bottom: -1.5rem !important;
}

.plr--1 {
  padding-left: -0.25rem !important;
  padding-right: -0.25rem !important;
}

.plr--2 {
  padding-left: -0.5rem !important;
  padding-right: -0.5rem !important;
}

.plr--3 {
  padding-left: -1rem !important;
  padding-right: -1rem !important;
}

.plr--4 {
  padding-left: -1.5rem !important;
  padding-right: -1.5rem !important;
}

.ptb--1 {
  padding-top: -0.25rem !important;
  padding-bottom: -0.25rem !important;
}

.ptb--2 {
  padding-top: -0.5rem !important;
  padding-bottom: -0.5rem !important;
}

.ptb--3 {
  padding-top: -1rem !important;
  padding-bottom: -1rem !important;
}

.ptb--4 {
  padding-top: -1.5rem !important;
  padding-bottom: -1.5rem !important;
}

/* Importing Switch SCSS file. */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #28a745;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Importing Switch SCSS file. */
/* Font Sizes */
.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

.text-xl {
  font-size: 20px;
}

/* Add more sizes as needed */
/* Font Weights */
.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

/* Text Colors */
.text-primary {
  color: #007BFF;
}

.text-secondary {
  color: #6C757D;
}

.text-success {
  color: #28A745;
}

.text-danger {
  color: #DC3545;
}

/* Text Alignments */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Text Decorations */
.text-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

/* Text Transformations */
.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/* Line Heights */
.text-leading-3 {
  line-height: 1.25;
}

.text-leading-4 {
  line-height: 1.5;
}

.text-leading-5 {
  line-height: 1.75;
}

/* Letter Spacing */
.text-tracking-tight {
  letter-spacing: -0.05em;
}

.text-tracking-normal {
  letter-spacing: 0;
}

.text-tracking-wide {
  letter-spacing: 0.05em;
}

/* Text Shadows */
.text-shadow-sm {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.text-shadow-md {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

/* Add more shadow sizes as needed */
/* More Custom Classes */
/* ... */
/* Hover States */
.text-hover-primary:hover {
  color: #0056b3;
}

.text-hover-success:hover {
  color: #1E7E34;
}

/* Add more hover styles as needed */
.pointer {
  cursor: pointer;
}

.w-5p {
  width: 50px;
}

.w-7p {
  width: 70px;
}

.w-1hp {
  width: 100px;
}

.w-1h2p {
  width: 120px;
}

.w-1h3p {
  width: 130px;
}

.w-1h5p {
  width: 150px;
}

.w-2hp {
  width: 200px;
}

.w-2h {
  width: 200px;
}

.w-3hp {
  width: 300px;
}

.w-4hp {
  width: 400px;
}

.w-5hp {
  width: 500px;
}

.w1h-p {
  width: 100%;
}

.w7-p {
  width: 70%;
}

.w5-p {
  width: 50%;
}

.w3-p {
  width: 30%;
}

.w1-p {
  width: 10%;
}

.w-1hper {
  width: 100% !important;
}

.w-a {
  width: auto !important;
}

.txt-l-btn {
  width: 100%;
  text-align: left;
}

.of-a {
  overflow: auto;
}

.of-h {
  overflow: hidden;
}

.of-s {
  overflow: scroll;
}

.of-x-a {
  overflow-x: auto;
}

.of-x-h {
  overflow-x: hidden;
}

.of-x-s {
  overflow-x: scroll;
}

.of-y-a {
  overflow-y: auto;
}

.of-y-h {
  overflow-y: hidden;
}

.of-y-s {
  overflow-y: scroll;
}

.txt-sm {
  font-size: small;
}

.txt-md {
  font-size: medium;
}

.va-n {
  vertical-align: unset !important;
}

.tbl-all-sm th, .tbl-sm td {
  margin: 0 !important;
  padding: 0.25rem !important;
  vertical-align: middle !important;
}

.tbl-h {
  max-height: 70vh;
  height: auto;
}

.td-sm {
  margin: 0 !important;
  padding: 0.25rem !important;
  vertical-align: unset !important;
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
}

a {
  text-decoration: unset !important;
}

.toast-container {
  position: fixed !important;
  z-index: 9999 !important;
  top: 12px !important;
  right: 12px;
}

.uploaded-img {
  height: 100px;
  width: auto;
  text-align: center;
  margin: 0 auto;
}

.final-controls {
  display: none;
}

.spliter {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.spliter::after {
  content: "- - - - - -";
}

.ps-stepper,
.ps-stepper-complete {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.ps-stepper i, .ps-stepper-complete i {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: large;
  border: 1px solid rgb(138, 138, 138);
  border-radius: 50%;
  padding: 24px;
  color: rgb(138, 138, 138);
}

.ps-stepper span {
  color: rgb(138, 138, 138);
  font-size: medium;
  font-weight: bold;
  margin-top: 8px;
}

.ps-stepper-complete i {
  border: 1px solid #3686fc;
  border-radius: 50%;
  color: #3686fc;
  padding: 24px;
}

.ps-stepper-complete span {
  color: #3686fc;
  font-size: medium;
  font-weight: bold;
}

.aln-center {
  margin: 0 auto;
}

.modal-width {
  width: 300px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .modal-width {
    width: 400px;
  }
}
@media (min-width: 1025px) {
  .modal-width {
    width: 500px;
  }
}
.modal-print-width {
  width: 500px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .modal-print-width {
    width: 650px;
  }
}
@media (min-width: 1025px) {
  .modal-print-width {
    width: 800px;
  }
}